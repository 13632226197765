.main-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-direction: column;
    border-left: 1px solid lightgray;
    padding-left: 30px;
    padding-right: 30px;
}

.content-container {
    height: 100%;
    display: flex;
    align-items: center;
}

.header {
    font-size: 20;
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
}

.header-title {
    padding-left: 20px;
    padding-top: 20px;
}

.tab-container {
    display: flex;
    height: 50px;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border-top: 1px solid lightgray;
    border-bottom: 2px solid black;
}

.tab {
    margin-left: 10px;
    margin-right: 10px;
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.tab-selected {
    margin-left: 10px;
    margin-right: 10px;
    background: lightgray;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.title {
    font-weight: bold;
    font-size: 22px;
}

.info-text {
    font-size: 22px;
}

.section {
    display: flex;
}

.content-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.content-info {
    display: flex;
    flex-direction: column;
    width: 300;
    height: 300;
    padding-left: 20px;
    padding-top: 20px;
    background: "blue";
}

.departments-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.dept-header {
    width: 100%;
    padding-top: 20px;
    padding-left: 20px;
    margin-bottom: 5px;
}

.unstyled-button {
    background: none;
	color: inherit;
	border: none;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.add-dept-button {
    border-radius: 10px;
    color: white;
    font-weight: bold;
    margin-bottom: 15px;
    display: flex;
    padding: 10px;
}

.button-hover {
    cursor: pointer,
}

.img {
    width: 200px !important;
    height: 200px !important;
    border-radius: 100%;
    object-fit: cover;
    margin-bottom: 20px;
    border-width: 2px;
    border-color: lightgray;
}