.unstyled-button {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.add-button {
    border: 1px solid tomato;
    background-color: tomato;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    margin-bottom: 15px;
}

.add-dept-button {
    border-radius: 10px;
    color: white;
    font-weight: bold;
    margin-bottom: 15px;
    display: flex;
    padding: 10px;
    align-items: center;
    height: 50px;
}

.add-button-disabled {
    border: 1px solid gray;
    background-color: gray;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    margin-bottom: 15px;
}

.pos-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 350px;
    height: 50px;
    border-top: 1px solid lightgray;
}

.pos-list-item-alt {
    width: 300px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pos-add-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 350px;
    height: 50px;
}

.pos-add-field {
    padding-top: 5px;
    height: 100%;
}

.pos-actions-container {
    display: flex;
    width: 100px;
    justify-content: flex-end;
    align-items: center;
}

.pos-form {
    width: 275px;
}

.pos-text-field {
    height: 10
}

.pos-text {
    font-weight: bold;
    font-size: 20;
    display: flex;
    width: 275px;
}

.pos-name-container {
    height: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 0px;
    padding-top: 10px;
}

.green {
    /* color: green; */
}

.body {
    display: flex;
}

.add-container {
    width: 50%;
    padding-left: 40px;
}

.pos-list-container {
    width: 40%;
}

.pos-list-container-modal-view {
    width: 100%
}