.unstyled-button {
    background: none;
	color: inherit;
	border: none;
	font: inherit;
	outline: inherit;
}

.button {
    background-color: #dc3545 !important;
    border-radius: 100px;
    color: white !important;
    font-weight: bold;
    margin-bottom: 15px; 
    padding-right: 15px;
    cursor: pointer;
    width: 230px;
}

.disabled {
    background-color: lightgray;
    border-radius: 100px;
    color: white;
    font-weight: bold;
    margin-bottom: 15px; 
    padding-right: 15px;
    cursor: not-allowed;
    width: 230px;
}

.button-content {
    display: flex !important;
    align-items: center !important;
}

.bg-add {
    background-color: #dc3545 !important;
}

.bg-save {
    background-color: #dc3545 !important;
}

.bg-cancel {
    background-color: gray!important;
}