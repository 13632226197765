.container {
  /* margin-top: 100px; */
  width: 100%;
  min-height: calc(100vh - (6rem + 4rem));
}
#page-container {
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.input-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  
}
.input-col {
  margin-left: 20px;
}
.input-style {
  text-align: center;
  margin-top: 20px;
}
.login-button {
  background-color: red;
  padding: 8px 50px 8px 50px ;
  border-radius: 10px;
  border-width: 1px;
  color: white;
  font-weight: bold;
  border-color: red;
  margin-top: 20px;
}
.image {
  align-self: center;
  justify-self: center;
  margin-top: 0;
  /* height: 120px !important; */
  /* width: 900px; */
}
.login-title {
  background-color: red;
  color: white;
  padding: 20px 300px 20px 300px;
  margin-top: 20px;
  margin-bottom: 40px;
  text-align: center;
}